import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

type ColorModes = 'light-mode' | 'dark-mode';

@Injectable()
export class ThemeService {
  private localStorageKey = 'color-theme';
  private colorModKeys = {
    lightMode: 'light-mode',
    darkMode: 'dark-mode'
  }

  private _lightMode = this._document.body.classList.contains(this.colorModKeys.lightMode);
  private $lightMode = new BehaviorSubject<boolean>(this._lightMode);

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  lightMode(): Observable<boolean> {
    return this.$lightMode.asObservable();
  }

  initByPreferred(): void {
    if (isPlatformBrowser(this.platformId)) {
      this._lightMode = window.matchMedia('(prefers-color-scheme: light)').matches;

      if (localStorage.getItem(this.localStorageKey))
        this._lightMode = localStorage.getItem(this.localStorageKey) === this.colorModKeys.lightMode;

      if (this._lightMode)
        this.goLight();
      else
        this.goDark();
      this.emitLightMode();
    }
  }

  setMode(newMode: ColorModes): void {
    switch (newMode) {
      case this.colorModKeys.lightMode:
        this.goLight();
        this._lightMode = true;
        break;
      case this.colorModKeys.darkMode:
        this.goDark();
        this._lightMode = false;
        break;
      default:
        break;

    }
    this.emitLightMode();
  }

  toggleMode(): void {
    this._lightMode = !this._lightMode;
    if (this._lightMode)
      this.goLight();
    else
      this.goDark();
    this.emitLightMode();
  }

  private goLight(): void {
    this._document.body.classList.remove(this.colorModKeys.darkMode);
    this._document.body.classList.add(this.colorModKeys.lightMode);
    localStorage.setItem(this.localStorageKey, this.colorModKeys.lightMode);
  }

  private goDark(): void {
    this._document.body.classList.remove(this.colorModKeys.lightMode);
    this._document.body.classList.add(this.colorModKeys.darkMode);
    localStorage.setItem(this.localStorageKey, this.colorModKeys.darkMode);
  }

  private emitLightMode(): void {
    this.$lightMode.next(this._lightMode);
  }
}
