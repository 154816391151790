import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loading = new BehaviorSubject(false);

  constructor(@Inject(DOCUMENT) private _document: Document) {
  }

  setLoading(val: boolean): void {
    if (val) {
      this._document.body.style.overflow = 'hidden';
    } else {
      this._document.body.style.overflow = 'auto';
    }
    this.loading.next(val);
  }

}
